import { gql } from "@apollo/client";

export const GET_ALL_PAYMENTS = gql`
    query getTransactionHistory(
        $skip: Int
        $take: Int
        $startDate: String
        $endDate: String
        $search: String
        $sort_model: [InputSort]
        $filter: String
    ) {
        getTransactionHistory(
            data: {
                skip: $skip
                take: $take
                startDate: $startDate
                endDate: $endDate
                search: $search
                sort_model: $sort_model
                filter: $filter
            }
        ) {
            status
            statusCode
            message
            error
            rowsCount
            data
        }
    }
`;
