import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { client } from "./ApolloClient";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { io } from "socket.io-client";
import { getCookie, clearCookie } from "shared/Assets/JS/Cookies";

var access_token = getCookie("admin_access_token");

const socket = io(process.env.REACT_APP_SOCKET_URL, {
    path: "/api/login/socket.io",
    query: { token: access_token },
});

socket.on("connect", () => {});

socket.on("connect_error", () => {});

socket.on("logout", () => {
    localStorage.clear();
    clearCookie();
    let currentHost = process.env.REACT_APP_HOST;
    currentHost = currentHost.substring(14);
    window.location.host = currentHost;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </ApolloProvider>,
);
