/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    CheckBox,
    Grid,
    IconButton,
    LoginBanner,
    Alert,
    GsFormProvider,
    FLoginTextField,
    FCheckBox,
} from "shared/Components";
import ReactLoading from "react-loading";

import { useForm } from "react-hook-form";
import styles from "../styles/Login.module.css";
import { InputAdornment, Snackbar } from "@mui/material";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useQuery } from "react-query";
import { OldSocialLogin as SocialLogin } from "react-social-login";
import MicrosoftLogin from "react-microsoft-login";
import { Helmet } from "react-helmet";
import { useLogin as useHookLogin, useLogin } from "../hooks/UseLogin";
import useAutoClose from "shared/Hooks/useAutoClose";
import { LoginTextField } from "../components/loginField";

const Login = () => {
    const navigate = useNavigate();
    const login = useHookLogin();
    const {
        handleLogin,
        handleSocialGoogleLogin,
        responseData,
        isLoading,
        message,
        clearMessage,
        handleMicrosoftLogin,
        setSocialData,
        handleSubmit,
        setValue,
        watch,
        errors,
        register,
        methods,
    } = useLogin();

    // const {
    // 	register,
    // 	handleSubmit,
    // 	setValue,
    // 	watch,
    // 	formState: { errors },
    // } = useForm({
    // 	defaultValues: {
    // 		email_id: "",
    // 		password: "",
    // 		remember_me: false,
    // 	},
    // });
    const { email_id, remember_me } = watch();
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    useAutoClose(message, clearMessage);

    const { data: SocialData, isLoading: vendorsLoading } = useQuery(
        "repoData",
        () =>
            fetch(`${process.env.REACT_APP_LOGIN_SERVER}/user/enable-social-vendors`).then((res) =>
                res.json(),
            ),
        {
            staleTime: Infinity,
        },
    );
    useEffect(() => {
        sessionStorage.clear();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Userlove | Sign In</title>
            </Helmet>
            {vendorsLoading ? (
                <div
                    id="BrowserLoader"
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "fixed",
                        zIndex: 10000000,
                        top: 0,
                        left: 0,
                        backgroundColor: "#fff",
                    }}
                >
                    <img
                        src="/image/BrowserLoaderMain.gif"
                        alt=""
                        style={{
                            width: "120px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                        }}
                    />
                </div>
            ) : (
                <Grid container className={styles["gs-login-page"]}>
                    <Grid
                        item
                        xl={6.6}
                        lg={6.6}
                        md={6.6}
                        sm={0}
                        xs={0}
                        height={"100%"}
                        className={styles["gs-login-banner-rightcontainer"]}
                    >
                        <div className={styles["gs-login-banner"]}>
                            <LoginBanner />
                        </div>
                    </Grid>
                    <Grid
                        item
                        // xs={6}
                        xl={5.4}
                        lg={5.4}
                        md={5.4}
                        sm={12}
                        xs={12}
                        height={"100%"}
                        sx={{ display: "flex ", alignItems: "center" }}
                        className={styles["gs-login-banner-leftcontainer"]}
                    >
                        <div className={styles["gs-login-fields"]}>
                            <div className={styles["gs-login-header"]}>
                                <img height={"45px"} alt="00" src="/image/loginLogo.png" />
                            </div>

                            <div className={styles["gs-login-form"]}>
                                <div className={styles["gs-login-header-text"]}>
                                    <h1>Welcome!</h1>
                                </div>

                                {/* <div className={styles["gs-login-social"]}>
                                    {SocialData?.data?.vendors?.map((vendor, i) => {
                                        return (
                                            <div key={i}>
                                                {vendor.authentication_server_name === "Google" &&
                                                    vendor.status === 1 && (
                                                    <SocialLogin
                                                        provider="google"
                                                        appId={
                                                            process.env.REACT_APP_GOOGLE_APP_ID
                                                        }
                                                        callback={handleSocialGoogleLogin}
                                                        redirectUri={
                                                            process.env
                                                                .REACT_APP_GOOGLE_REDIRECT
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles["gs-login-social-item"]
                                                            }
                                                        >
                                                            <img
                                                                alt="GoogleLogo"
                                                                height={"18px"}
                                                                src="/image/google-logo.png"
                                                                // src={`${process.env.REACT_APP_GRAPHQL_SERVER}${vendor?.logo}`}
                                                            />
                                                            <p>Sign in with Google</p>
                                                        </div>
                                                    </SocialLogin>
                                                )}
                                                {vendor.authentication_server_name ===
                                                    "Microsoft" &&
                                                    vendor.status === 1 && (
                                                    <MicrosoftLogin
                                                        prompt="select_account"
                                                        clientId={
                                                            process.env
                                                                .REACT_APP_MICROSOFT_APP_ID
                                                        }
                                                        authCallback={(err, data, msal) => {
                                                            handleMicrosoftLogin(
                                                                err,
                                                                data,
                                                                msal,
                                                            );
                                                        }}
                                                        // redirectUri={
                                                        //   "https://userlove.my:3000/login/microsoft"
                                                        // }
                                                        redirectUri={
                                                            process.env
                                                                .REACT_APP_MICROSOFT_REDIRECT
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles["gs-login-social-item"]
                                                            }
                                                            tabIndex="0"
                                                        >
                                                            <img
                                                                alt="MicrosoftLogo"
                                                                height={"18px"}
                                                                // src={`${process.env.REACT_APP_GRAPHQL_SERVER}${vendor?.logo}`}

                                                                src="/image/Microsoft.svg"
                                                            />
                                                            <p>Sign in with Microsoft</p>
                                                        </div>
                                                    </MicrosoftLogin>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div> */}
                                {/* {SocialData && SocialData?.data?.vendors?.length !== 0 && (
                                    <div className={styles["gs-login-or"]}>
                                        <div className={styles["gs-login-or-line"]}></div>
                                        <p>or sign in with email </p>
                                        <div className={styles["gs-login-or-line"]}></div>
                                    </div>
                                )} */}

                                <Snackbar
                                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                    open={!!message.text}
                                >
                                    <Alert
                                        icon={false}
                                        size="medium"
                                        type={message.type}
                                        sx={{ width: "auto", textAlign: "center" }}
                                    >
                                        {message.text}
                                    </Alert>
                                </Snackbar>

                                {/* <form */}
                                <GsFormProvider
                                    methods={methods}
                                    onSubmit={handleSubmit(handleLogin)}
                                    className={styles["gs-login-form-fields"]}
                                >
                                    <FLoginTextField
                                        // {...register("email_id", {
                                        // 	required: "Email is required",
                                        // 	pattern: {
                                        // 		value: new RegExp(
                                        // 			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        // 		),
                                        // 		message: "Invalid email address",
                                        // 	},
                                        // })}
                                        name="email_id"
                                        sx={{ width: "100%" }}
                                        placeholder="you@company.com"
                                        label="Email Address"
                                        fullWidth
                                        require={true}
                                        // helperText={errors.email_id?.message}
                                        // inValid={!!errors.email_id}
                                        autoFocus="autoFocus"
                                    />

                                    <FLoginTextField
                                        // 				{...register("password", {
                                        // 					required: "Password is required",
                                        // 					pattern: {
                                        // 						value: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).+$/),
                                        // 						message:
                                        //   "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
                                        // 					},
                                        // 					minLength: {
                                        // 						value: 8,
                                        // 						message:
                                        //   "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
                                        // 					},
                                        // 				})}
                                        name={"password"}
                                        sx={{ width: "100%" }}
                                        placeholder="Enter your password"
                                        label="Password"
                                        fullWidth
                                        type={showPassword ? "text" : "password"}
                                        // helperText={errors.password?.message}
                                        // inValid={!!errors.password}
                                        require={true}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        className="gs-password-icon"
                                                    >
                                                        {showPassword ? <BsEye /> : <BsEyeSlash />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {responseData &&
                                        responseData.data &&
                                        responseData.data.loginWithOTP && (
                                        <div className={styles["gs-login-form-fields-forgot"]}>
                                            <div
                                                className={
                                                    styles["gs-login-form-fields-otp-login"]
                                                }
                                            >
                                                    Forgot password?
                                            </div>
                                            <p
                                                onClick={() => {
                                                    navigate("/otp-login", {
                                                        state: {
                                                            responseData: responseData,
                                                            email: email_id,
                                                        },
                                                    });
                                                }}
                                                className={styles["forget-text"]}
                                            >
                                                    Login with OTP
                                            </p>
                                        </div>
                                    )}
                                    <div className={styles["gs-login-form-fields-forgot"]}>
                                        <div
                                            className={
                                                styles["gs-login-form-fields-forgot-checkbox"]
                                            }
                                        >
                                            <FCheckBox
                                                style={{ padding: "0px", marginRight: "5px" }}
                                                label={"Remember me"}
                                                name="remember_me"
                                                value={remember_me}
                                                onChange={(e) => {
                                                    setValue("remember_me", e.target.checked);
                                                }}
                                            />
                                        </div>
                                        <p
                                            onClick={() => {
                                                navigate("/forgot-password", {
                                                    state: { email_id },
                                                });
                                            }}
                                            className={styles["forget-text"]}
                                        >
                                            Forgot password?
                                        </p>
                                    </div>
                                    <Button
                                        sx={{ marginTop: "0px" }}
                                        type="submit"
                                        className={"gs-primary-button"}
                                        // style={{ height: "50px" }}
                                        style={{ height: "44px", marginTop: "10px" }}
                                        loading={isLoading}
                                    >
                                        {/* {isLoading ? (
											<ReactLoading
												className='gs-enable-disable-loader-bubble'
												type={"bubbles"}
												color={"var(--white-color)"}
											/>
										) : (
											"  Sign in"
										)} */}
                                        Sign in
                                    </Button>

                                    {/* <div className={styles["gs-login-form-signup-button"]}>
                                        <p style={{ color: "var(--secondary-light-color)" }}>
                                            Don't have an account?
                                        </p>
                                        <Button
                                            onClick={() => {
                                                setSocialData(null);

                                                navigate("/register");
                                            }}
                                            className={styles["signup-signin-text"]}
                                        >
                                            Sign up
                                        </Button>
                                    </div> */}
                                    {/* </form> */}
                                </GsFormProvider>
                            </div>

                            <div className={styles["gs-login-footer"]}>
                                <p>
                                    © {new Date().getFullYear()} Userlove LLC.
                                    <span style={{ textTransform: "capitalize" }}>
                                        All rights reserved.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Grid>
                    {/* <Grid
            item
            xs={6}
            height={"100%"}
            className={styles["gs-login-banner-rightcontainer"]}
          >
            <div className={styles["gs-login-banner"]}>
              <LoginBanner />
            </div>
          </Grid> */}
                </Grid>
            )}
        </>
    );
};
export default Login;
