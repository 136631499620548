import React from "react";
import Layout from "Routes/Layout";
import { Navigate, Outlet } from "react-router-dom";

import {
    AuthenticationServices,
    BookDemoForm,
    BusinessSettings,
    Countries,
    Currencies,
    Payments,
    // Dashboard,
    EmailTemplates,
    EmailTemplatesCategory,
    EmailTemplatesKey,
    ForgotPassword,
    GeneralSettings,
    GeoZone,
    InvitationLink,
    LanguagesSettings,
    LocalVariables,
    Login,
    NotificationSettings,
    OtpConformation,
    OtpLogin,
    PrivacyPolicy,
    RegisterEmailVerify,
    Registration,
    ResetPassword,
    SetPassword,
    SMSServices,
    SMTPSettings,
    States,
    RoleAccess,
    // EarlyAccessRequest,
    SystemCategory,
    TermsService,
    TwoFAVerification,
    // DemoRequest,
    Organization,
    // Users,
    Roles,
    AddRolePermission,
    SystemUsers,
    AppList,
    ProductTourType,
    ProductTourTemplate,
    NPSType,
    AppFeatures,
    SubFeatures,
    // FeatureType,
    // InputTypes,
    // LicenseType,
    SurveyElementsType,
    DefaultSurvey,
    Quotation,
    Packages,
    BasePrice,
    Discount,
    Coupon,
    MAUsRange,
    // EarlyAccess,
    TenantSettings,
    Integration,
} from "modules";

import {
    AppsIcons,
    // EarlyAccessIcon,
    HelpIcon,
    HomeIcons,
    OrgIcons,
    PaymentIcons,
    PlansIcons,
    SettingsIcons,
    TempsIcons,
} from "shared/Assets/Icons";
import Payment from "modules/payments/Payment";

const routes = {
    common: [
        {
            path: "unauthorized",
            component: () => <div>unauthorized </div>,
        },
        { path: "*", component: () => <div>Not Found!</div> },
    ],
    private: [
        {
            path: "/",
            component: Layout,
            children: [
                {
                    index: true,
                    component: () => <Navigate to="/dashboard" replace={true} />,
                },
                {
                    path: "dashboard",
                    component: () => <h2>Dashboard</h2>,
                    text: "DASHBOARD",
                    icon: <HomeIcons />,
                },
                {
                    text: "ORG",
                    path: "organizations",
                    icon: <OrgIcons />,
                    component: Outlet,
                    children: [
                        {
                            index: true,
                            component: () => <Navigate to="/organizations/organization" />,
                        },
                        // {
                        //     path: "/organizations/early-access-request",
                        //     component: EarlyAccessRequest,
                        //     text: "Early access request",
                        //     defaultProps: {
                        //         title: "Early access request",
                        //         description: "You can accept and denied early access request.",
                        //     },
                        // },
                        {
                            path: "/organizations/organization",
                            component: Organization,
                            text: "Organization",
                            defaultProps: {
                                title: "Organizations",
                                description: "Manage organization",
                            },
                        },
                        // {
                        //     path: "/organizations/users",
                        //     component: Users,
                        //     text: "Users",
                        //     defaultProps: {
                        //         title: "Users",
                        //         description: "Your all users details here.",
                        //     },
                        // },
                        // ...(["production", "staging"].includes(process.env.REACT_APP_ENV)
                        //     ? []
                        //     : [
                        //           {
                        //               path: "/organizations/roles",
                        //               text: "Roles",
                        //               component: Outlet,
                        //               children: [
                        //                   {
                        //                       index: true,
                        //                       component: Roles,
                        //                   },
                        //                   { path: "create-new", component: AddRolePermission },
                        //               ],
                        //           },
                        //       ]),
                        //  {
                        //     path: "/organizations/system-users",
                        //     component: SystemUsers,
                        //     text: "System Users",
                        //     defaultProps: {
                        //         title: "System Users",
                        //         description: "Update your system users here",
                        //     },
                        //  },
                        {
                            path: "/organizations/quotation",
                            component: Quotation,
                            text: "Quotation",
                            defaultProps: {
                                title: "Quotation",
                                description: "Manage quotation",
                            },
                        },
                    ],
                },
                {
                    text: "APPS",
                    path: "app",
                    icon: <AppsIcons />,
                    component: Outlet,
                    children: [
                        {
                            index: true,
                            component: () => <Navigate to="/app/applications" />,
                        },
                        {
                            path: "/app/applications",
                            component: AppList,
                            text: "Applications",
                            defaultProps: {
                                title: "Applications",
                                description: "Manage applications",
                            },
                        },
                        {
                            path: "/app/applications/:appid/features",
                            component: AppFeatures,
                            text: "",
                            showSidebar: false,
                            defaultProps: {
                                title: "App Features List",
                                description: "Manage applications",
                            },
                        },
                        {
                            path: "/app/applications/:appid/features/:fid/subfeatures",
                            component: SubFeatures,
                            text: "",
                            showSidebar: false,
                            defaultProps: {
                                title: "Sub Features",
                                description: "Manage applications",
                            },
                        },
                        // {
                        //   path: "/app/features",
                        //   component: Features,
                        //   text: "Features",
                        // },
                        // {
                        //   path: "/app/feature-type",
                        //   component: FeatureType,
                        //   text: "Feature type",
                        // },
                        // {
                        //   path: "/app/input-types",
                        //   component: InputTypes,
                        //   text: "Input Types",
                        // },
                        // {
                        //   path: "/app/license-type",
                        //   component: LicenseType,
                        //   text: "License Types",
                        // },
                    ],
                },
                {
                    text: "PLANS",
                    path: "/plans",
                    icon: <PlansIcons />,
                    component: Outlet,
                    children: [
                        {
                            index: true,
                            component: () => <Navigate to="/plans/packages" />,
                        },
                        {
                            path: "/plans/packages",
                            component: Packages,
                            text: "Packages",
                            defaultProps: {
                                title: "Price and Plan",
                                description: "Manage  create ,delete and update Plan",
                            },
                        },
                        {
                            path: "/plans/base-price",
                            component: BasePrice,
                            text: "Base price",
                            defaultProps: {
                                title: "Base Price",
                                description: "Set basic/base price of each feature.",
                            },
                        },
                        {
                            path: "/plans/discount",
                            component: Discount,
                            text: "Discount",
                            defaultProps: {
                                title: "Discount",
                                description: "Manage and create discount on packages.",
                            },
                        },
                        {
                            path: "/plans/coupon",
                            component: Coupon,
                            text: "Coupon",
                            defaultProps: {
                                title: "Coupon",
                                description: "Manage and create Coupon on packages.",
                            },
                        },
                        {
                            path: "/plans/MAUsRange",
                            component: MAUsRange,
                            text: "MAUs Range",
                            defaultProps: {
                                title: "MAUs Range",
                                // description: "Manage and create Coupon on packages.",
                            },
                        },
                    ],
                },
                {
                    text: "PAYMENTS",
                    path: "/users",
                    icon: <PaymentIcons />,
                    component: Payment,
                    defaultProps: {
                        title: "Orgnization Payments",
                    },
                },
                {
                    text: "TEMPS",
                    path: "/templates",
                    icon: <TempsIcons />,
                    component: Outlet,
                    children: [
                        {
                            index: true,
                            component: () => <Navigate to="/templates/type-product-tour" />,
                        },
                        {
                            path: "/templates/type-product-tour",
                            component: ProductTourType,
                            text: "Product Tour Type",
                            defaultProps: {
                                title: "Product Tour Type",
                                description: "You can add, update, delete Product tour type",
                            },
                        },
                        {
                            path: "/templates/templates-product-tour",
                            component: ProductTourTemplate,
                            text: "Product Tour Temp",
                            separator: true,
                            defaultProps: {
                                title: "Product Tour Templates",
                                description: "You can add, update, delete Product tour template",
                            },
                        },
                        {
                            path: "/templates/Survey-Type",
                            component: NPSType,
                            text: "Survey Category",
                            defaultProps: {
                                title: "Survey Type",
                                description: "You can add, update, delete NPS Type",
                            },
                        },
                        {
                            path: "/templates/Survey-Elements-Type",
                            component: SurveyElementsType,
                            text: "Survey Elements",
                            defaultProps: {
                                title: "Survey Elements Type",
                                description: "You can add, update, delete Survey Elements Type",
                            },
                        },
                        {
                            path: "/templates/Default-Survey",
                            component: DefaultSurvey,
                            text: "Default Survey",
                            defaultProps: {
                                title: "Default Survey",
                                description: "You can update, delete Survey",
                            },
                        },
                    ],
                },
                // {
                //     text: "EARLY",
                //     path: "/early-access-request",
                //     icon: <EarlyAccessIcon />,
                //     component: EarlyAccess,
                //     defaultProps: {
                //         title: "Early access request",
                //         description: "You can accept and denied early access request.",
                //     },
                // },
                { break: true },
                {
                    text: "SETTINGS",
                    path: "settings",
                    icon: <SettingsIcons />,
                    component: Outlet,
                    children: [
                        {
                            index: true,
                            component: () => <Navigate to="/settings/general" />,
                        },
                        { label: "General Settings" },
                        {
                            path: "/settings/general",
                            component: GeneralSettings,
                            text: "General",
                            defaultProps: {
                                title: "General Settings",
                                description: "Update your details here",
                            },
                        },
                        ...(["production", "staging"].includes(process.env.REACT_APP_ENV)
                            ? []
                            : [
                                {
                                    path: "/settings/roles",
                                    text: "Roles",
                                    component: Outlet,
                                    children: [
                                        {
                                            index: true,
                                            component: Roles,
                                        },
                                        { path: "create-new", component: AddRolePermission },
                                    ],
                                },
                            ]),
                        {
                            path: "/settings/system-users",
                            component: SystemUsers,
                            text: "System Users",
                            defaultProps: {
                                title: "System Users",
                                description: "Update your system users here",
                            },
                        },
                        {
                            path: "/settings/business",
                            component: BusinessSettings,
                            text: "Business",
                            defaultProps: {
                                title: "Business Settings",
                                description: "Update your bussiness detail here",
                            },
                        },
                        {
                            path: "/settings/migrations",
                            component: TenantSettings,
                            text: "Migrations",
                            defaultProps: {
                                title: "Manage migrations",
                                description: "text",
                            },
                        },
                        {
                            path: "/settings/smtp",
                            component: SMTPSettings,
                            text: "SMTP",
                            defaultProps: {
                                title: "SMTP Settings",
                                description: "Update your SMTP detail here.",
                            },
                        },
                        {
                            path: "/settings/notification",
                            component: NotificationSettings,
                            text: "Notification",
                            defaultProps: {
                                title: "Notification Settings",
                                description: "Update your notification settings here",
                            },
                            separator: true,
                        },
                        { label: "Email Settings" },
                        {
                            path: "/settings/emailtemplates",
                            component: EmailTemplates,
                            text: "Email Templates",
                            defaultProps: {
                                title: "Email Templates",
                                description: "You can add, edit, and delete your email templates.",
                            },
                        },

                        {
                            path: "/settings/tempcategory",
                            component: EmailTemplatesCategory,
                            text: "Email Category",
                            defaultProps: {
                                title: "Email Template Category",
                                description:
                                    "You can add, edit, and delete your email templates category.",
                            },
                        },
                        {
                            path: "/settings/email-master",
                            component: EmailTemplatesKey,
                            text: "Email Master",
                            defaultProps: {
                                title: "Email Master Template",
                                description: "Create your master email templates here",
                            },
                            separator: true,
                        },

                        { label: "Services" },
                        {
                            path: "/settings/currencies",
                            component: Currencies,
                            text: "Currencies",
                            defaultProps: {
                                title: "Currencies",
                                description: "You can add, edit, and delete your currencies.",
                            },
                        },
                        {
                            path: "/settings/payments",
                            component: Payments,
                            text: "Payments",
                            defaultProps: {
                                title: "Payments",
                                description: "Create and update your payments details.",
                            },
                        },
                        {
                            path: "/settings/authentication-services",
                            component: AuthenticationServices,
                            text: "Auth Services",
                            defaultProps: {
                                title: "Authentication Services",
                                description:
                                    "You can add, edit, and delete your authentication services.",
                            },
                        },
                        {
                            path: "/settings/integration",
                            component: Integration,
                            text: "Integration",
                            defaultProps: {
                                title: "Integration services",
                                description:
                                    "You can add, edit, and delete your integration services.",
                            },
                        },
                        {
                            path: "/settings/sms-services",
                            component: SMSServices,
                            text: "SMS Services",
                            separator: true,
                            defaultProps: {
                                title: "SMS Services",
                                description: "Update your SMS detail here",
                            },
                        },
                        { label: "Localizations" },
                        {
                            path: "/settings/languages",
                            component: LanguagesSettings,
                            text: "Languages",
                            defaultProps: {
                                title: "Language Settings",
                                description: "You can add, edit, and delete languages.",
                            },
                        },
                        {
                            path: "/settings/variables",
                            text: "Variables",
                            component: LocalVariables,
                            defaultProps: {
                                title: "Localization Variables",
                                description: "You can update localization variables here.",
                            },
                        },
                        {
                            path: "/settings/countries",
                            component: Countries,
                            text: "Countries",
                            defaultProps: {
                                title: "Countries",
                                description: "You can add, edit and delete your conutries.",
                            },
                        },
                        {
                            path: "/settings/states",
                            component: States,
                            text: "States",
                            defaultProps: {
                                title: "States",
                                description: "You can add, edit and delete your states.",
                            },
                        },
                        {
                            path: "/settings/geo-zone",
                            component: GeoZone,
                            text: "Geo Zone",
                            defaultProps: {
                                title: "Geo Zone",
                                description: "You can add, edit and delete your geo zone.",
                            },
                        },
                        {
                            path: "/settings/role-access",
                            component: RoleAccess,
                            text: "Role access",
                            defaultProps: {
                                title: "Role Of Early Access",
                                description: "You can add, edit and delete role access.",
                            },
                        },
                        {
                            path: "/settings/systemcategory",
                            component: SystemCategory,
                            text: "Sandbox Category",
                            defaultProps: {
                                title: "Sandbox Category",
                                description: "You can add, edit and delete your sandbox category.",
                            },
                        },
                        // {
                        //     path: "/settings/demo-request",
                        //     text: "Demo Request",
                        //     component: DemoRequest,
                        //     defaultProps: {
                        //         title: "Demo Request",
                        //         description: "You can edit your demo request.",
                        //     },
                        // },
                    ],
                },
                {
                    text: "Help",
                    path: "https://userlove.gitbook.io/help-center/",
                    icon: <HelpIcon />,
                    external: true,
                    target: "_blank",
                },
            ],
        },
    ],
    public: [
        { path: "login", component: Login },
        { path: "register", component: Registration },
        { path: "otp-login", component: OtpLogin },
        { path: "forgot-password", component: ForgotPassword },
        { path: "two-factor-auth", component: TwoFAVerification },
        { path: "reset-password/:token", component: ResetPassword },
        { path: "otp-conformation", component: OtpConformation },
        { path: "email-verification", component: RegisterEmailVerify },
        { path: "invitation-link/:token", component: InvitationLink },
        { path: "set-password/:token", component: SetPassword },
        { path: "book-demo", component: BookDemoForm },
        { path: "terms-of-service", component: TermsService },
        { path: "privacy-policy", component: PrivacyPolicy },
    ],
};

export default routes;
